<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Dataset from "vue-dataset/dist/es/Dataset.js";
import DatasetItem from "vue-dataset/dist/es/DatasetItem.js";
import DatasetPager from "vue-dataset/dist/es/DatasetPager.js";
import DatasetShow from "vue-dataset/dist/es/DatasetShow.js";
export default {
  page: {
    title: "Mes notifications",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    Layout,
    PageHeader,
    Dataset,
    DatasetItem,
    DatasetPager,
    DatasetShow,
  },
  data() {
    return {
      title: "Mes notifications",
      cols: [
          {
          name: "N°",
          field: "no",
          sort: "",
        },
        {
          name: "Date",
          field: "date",
          sort: "",
        },
        {
          name: "Par",
          field: "by",
          sort: "",
        },
        {
          name: "Type",
          field: "type",
          sort: "",
        },
        {
          name: "Message",
          field: "msg",
          sort: "",
        },
        
      ],
      notificationsList: [],
      items: [
        {
          text: "Paramètres",
          to: { name: "base.settings.index" },
        },
        {
          text: "Mes notifications",
          active: true,
        },
      ],
    };
  },

  watch: {},

  mounted() {
      this.fetchNotifications();
  },

  methods: {
    fetchNotifications(){
      this.$http
        .post("/auth/notifications")
        .then((res) => {
          this.notificationsList = res.data.unreadNotifs;
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <dataset v-slot="{ ds }" :ds-data="notificationsList">
      <div class="row">
        <div class="col-md-12">
          <div class="">
            <table class="table table-hover d-md-table">
              <thead>
                <tr>
                  <th
                    v-for="th in cols"
                    :style="th.field == 'objet' ? 'width: 20%;' : ''"
                    :key="th.field"
                  >
                    {{ th.name }}
                  </th>
                </tr>
              </thead>
              <dataset-item tag="tbody">
                <template #default="{ row, rowIndex }">
                  <tr v-bind:key="rowIndex">
                    <td>{{ rowIndex + 1 }}</td>
                    <td><a href="javascript:void(0)">{{ row.generatedAt }}</a></td>
                    <td>{{ row.emitedBy }}</td>
                    <td> <span class="badge" :class="'badge-'+row.class">{{ row.title }}</span> </td>
                    <td> {{ row.extra ? row.extra.msg : 'N/A' }} </td>
                  </tr>
                </template>
              </dataset-item>
            </table>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-md-row flex-column justify-content-between align-items-center"
      >
        <!-- <dataset-info class="mb-2 mb-md-0" /> -->

        <dataset-show :ds-show-entries="5" />
        <dataset-pager />
      </div>
    </dataset>
  </Layout>
</template>
